<template>
<div>Page not found</div>
</template>

<script>
export default {
  name: "404.vue"
}
</script>

<style scoped>

</style>